import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoadingOverlay from "react-loading-overlay-ts";

export default function Gallery(props) {
  const [gLoader, setGLoader] = useState(true);
  const [show, setShow] = useState(props.show);
  const [itemInd, setInd] = useState(0); // Track the current index
  const [flightInfo, setFlightInfo] = useState(props.flight);
  const [galImages, setGalImages] = useState([]);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setFlightInfo(props.flight);
    let photos = props.flight.tailPhotos || [];

    if (photos.length > 0) {
      setGalImages(photos);
    } else {
      setGalImages([]);
    }
  }, [props.flight]);

  useEffect(() => {
    setGLoader(false);
  }, [galImages]);

  // const handleCarouselChange = (index) => {
  //   if (index < galImages.length) {
  //     setInd(index);
  //   }
  // };

  const sliderChange = (e) => {
    let val = e.target.value;
    setInd(parseInt(val));
  };

  const closeGal = () => {
    props.closeGallery(false);
    setInd(0);
  };

  const galTitleRef = useRef(null);
  // useEffect(() => {
  //   if (galTitleRef.current) {
  //     const trimmedText = galTitleRef.current.textContent.trim();
  //     setTitleText(trimmedText);
  //   }
  // }, [flightInfo.modelDescription]);

  const handleSwipeEnd = () => {
    if (itemInd >= galImages.length - 1) {
      setInd(galImages.length - 1);
    }
  };
  return (
    <>
      <Modal
        show={show}
        fullscreen={"true"}
        onHide={() => closeGal()}
        dialogClassName="modal-100w"
      >
        <Modal.Header>
          <Modal.Title>
            <div className="title_ctr">
              <div>
                <div className="back_btn" onClick={() => closeGal()}>
                  &lt; Back
                </div>
                <div className="close_btn" onClick={() => closeGal()}>
                  &#10006;
                </div>
              </div>
              {flightInfo.hasOwnProperty("modelDescription") &&
                flightInfo.modelDescription !== null && (
                  <h3
                    // className={`gal_title ${
                    //   titleText.length > 20 ? "gal_title_padding" : ""
                    // }`}
                    className="gal_title"
                    ref={galTitleRef}
                  >
                    {flightInfo.modelDescription}
                  </h3>
                )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="img-wrapper">
            <Carousel
              autoPlay={false}
              selectedItem={itemInd}
              showThumbs={false}
              onChange={(index) => {
                if (index < galImages.length) {
                  setInd(index);
                } else {
                  setInd(galImages.length - 1);
                }
              }}
              onSwipeEnd={handleSwipeEnd}
            >
              {galImages.length > 0 ? (
                galImages.map((item, gInd) => (
                  <div className="img-cont" key={`gal-img-${gInd}`}>
                    <img src={item} alt={`gal-img-${gInd}`} />
                  </div>
                ))
              ) : (
                <div>No aircraft photos found...</div>
              )}
            </Carousel>
          </div>

          {galImages.length > 1 && (
            <input
              type="range"
              name="itemInd"
              value={itemInd}
              min="0"
              step="1"
              max={galImages.length - 1}
              onChange={(e) => sliderChange(e)}
              style={{ border: "none", outline: "none" }}
            />
          )}

          <LoadingOverlay
            active={gLoader}
            spinner
            text="Loading aircraft images"
            fadeSpeed="1000"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
