import React, { useEffect, useState } from "react";
import panelFlightIcon from "../../assets/icons/flight-icon.png";
import panelEditIcon from "../../assets/icons/edit-icon.png";
import { getEleAttribute, API_PATH, randomId } from "./utility";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function ChooseAFlightHeader({ searchOptions = {}, theme, referralUrl = "" }) {
  const [travelCities, setTravelCities] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("travelCities")) {
      setTravelCities(JSON.parse(localStorage.getItem("travelCities")));
    }
  }, [localStorage.getItem("travelCities")]);

  const backToWidget = async () => {
    let res = JSON.parse(localStorage.getItem("searchCriteria"));
    console.log(travelCities);
    let operatorAwsKey = randomId(9);
    let searchParams = {
      operatorAwsKey: operatorAwsKey, // Assuming this.randomId is a utility function
      searchAircraftRequest: {
        routeType: res.routeType,
        travelCities: travelCities,
        flyShare: false,
        passengers: res.passengers,
        petFriendly: res.petFriendly,
        sessionId: "35fa1145-b8e3-463a-b55f-e764e8ee9a27", // Example session ID
      },
      email: res.email,
      referralUrl: referralUrl,
    };
    let success = false;
    const requestOptionsse = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(searchParams),
    };
    const apiUrlse = `${API_PATH}white_label/post-aircraft-request-wl`;
    await fetch(apiUrlse, requestOptionsse)
      .then((response) => response.json())
      .then((result) => {
        success = result.success;
      })
      .catch((error) => {
        console.log("error", error);
      });

    // window.onbeforeunload = null;
    if (success) {
      window.location.href = referralUrl + `?operatorKey=${operatorAwsKey}`;
    } else {
      toast.error("Sorry, Something went wrong!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        icon: false,
        // transition: "Bounce",
      });
    }
  };
  return (
    <div className="ChooseAFlightHeader">
      <ToastContainer />
      <div
        className="route-info-container"
        onClick={() => backToWidget()}
        style={{ background: getEleAttribute("customPrimaryColor", theme) }}
      >
        <div className="panel-icon-box">
          <div className="panel-icon-flight-vector">
            <img src={panelFlightIcon} alt={"panel-flight=icon"} />
          </div>
        </div>
        {travelCities[0] ? (
          <>
            {searchOptions?.routeType !== "MULTI_CITY" && (
              <div className="route-info">
                <p className="airport">({travelCities[0]?.origin})</p>
                <p className="suffix">TO</p>
                <p className="airport">
                  ({travelCities[travelCities.length - 1]?.destination})
                </p>
              </div>
            )}
            {searchOptions?.routeType === "MULTI_CITY" && (
              <div className="route-info">
                <p className="airport">({travelCities[0]?.origin})</p>
                <p className="suffix">TO</p>
                <p className="airport">
                  ({travelCities[travelCities.length - 1]?.destination})
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="route-info">
            <Spinner animation="border" />
          </div>
        )}
        <div className="panel-icon-box">
          <div className="panel-icon-edit-vector">
            <img src={panelEditIcon} alt={"panel-edit-icon"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseAFlightHeader;
