import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  modalOpen: false,
  flightdata: {},
  theme: {},
  operatorId: "",
  quoteSuccess: false,
  marketplace: false,
};

const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    quoteModalAction: (state, action) => {
      state.modalOpen = action.payload.modalOpen;
      state.flightdata = action.payload.flightdata;
    },
    loadOperatorTheme: (state, action) => {
      state.theme = action.payload.theme;
      state.operatorId = action.payload.operatorId;
      state.marketplace = action.payload.marketPlace;
      state.buttonText = action.payload.buttonText;
    },
    quoteReset: (state) => {
      state.quoteSuccess = false;
    },
    quoteSet: (state) => {
      state.quoteSuccess = true;
    },
  },
});

export const { loadOperatorTheme, quoteSummaryAction, quoteReset, quoteSet } =
  widgetSlice.actions;

export default widgetSlice.reducer;
