import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routing";
import { API_PATH } from "./containers/ChooseAFlight/utility";
import { setSessionID } from "./utils/session.storage.utils";
import "./styles/root.scss";
import "./styles/guide.scss";
import { useDispatch } from "react-redux";
import { loadTheme } from "./actions/widget.actions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    setSessionID();
  }, []);
  useEffect(() => {
    const requestOptions = {};
    fetch(
      API_PATH + "white_label/get-configuration-template?operatorKey=abc777xyz",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(
          loadTheme(
            result.configurationTemplate.searchresult,
            result.operatorId,
            result.marketPlace,
            result.configurationTemplate.searchwidget.buttonText
          )
        );
      })
      .catch((error) => console.log("error", error));
  }, []);
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.body.classList.add("safari");
    }
  }, []);

  // const isDomainValid = validateDomain();
  // if (!isDomainValid) {
  //   return null;
  // }

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
