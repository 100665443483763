import {
  loadOperatorTheme,
  quoteSummaryAction,
  quoteReset,
  quoteSet,
} from "../reducers/WidgetReducer";

export const loadTheme =
  (theme, operatorId, marketPlace, buttonText) => (dispatch) => {
    dispatch(loadOperatorTheme({ theme, operatorId, marketPlace, buttonText }));
  };
export const handleQuoteSummary = (quoteSuccess, modalOpen) => (dispatch) => {
  dispatch(quoteSummaryAction(quoteSuccess, modalOpen));
};
export const handleQuoteReset = () => (dispatch) => {
  dispatch(quoteReset());
};
export const handleQuoteSet = () => (dispatch) => {
  dispatch(quoteSet());
};
